.swagger-ui {
  background-color: #f4f7fa;
}

.swagger-ui .info.info {
  margin: 0;
}

.swagger-ui .information-container.wrapper {
  padding: 2em;
}
